import { createRoute, useParams } from "@tanstack/react-router";
import { RoomPage } from "../pages/roomPage/roomPage";
import { rootRoute } from "./rootRoute";
import { routes } from "./routes";

export const roomRoute = createRoute({
	getParentRoute: () => rootRoute,
	path: routes.roomRoute,
	component: () => {
		// eslint-disable-next-line react-hooks/rules-of-hooks
		const { roomNumber } = useParams({ from: roomRoute.id });
		return <RoomPage roomNumber={roomNumber} />;
	},
});
