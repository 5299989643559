import { t } from "@lingui/macro";
import { FiClipboard } from "react-icons/fi";
import { toast } from "sonner";

export const FieldWithClipBoardButton = ({
	displayText,
	className,
	textToCopy,
}: { displayText: string; textToCopy?: string; className?: string }) => {
	const handleCopy = async () => {
		try {
			await navigator.clipboard.writeText(textToCopy ?? displayText);
			toast.success(t`Copied room link to clipboard!`);
		} catch (err) {
			toast.error(t`Failed to copy!`);
		}
	};

	return (
		<div className="flex justify-center items-center space-x-2 mx-auto">
			<span className={className}>{displayText}</span>
			<button
				type="button"
				onClick={handleCopy}
				className="text-blue-500 hover:text-blue-700"
			>
				<FiClipboard size={20} />
			</button>
		</div>
	);
};
