import { create } from "zustand";

type UseOpenFullNameDialog = {
	fullNameDialogIsOpen: boolean;
	setFullNameDialogIsOpen: (a: boolean) => void;
};

export const useOpenFullNameDialog = create<UseOpenFullNameDialog>((set) => ({
	fullNameDialogIsOpen: true,
	setFullNameDialogIsOpen: (fullNameDialogIsOpen) =>
		set({ fullNameDialogIsOpen }),
}));

type AnonSwitch = {
	isAnon: boolean;
	setIsAnon: (isAnon: boolean) => void;
};

export const useAnonSwitch = create<AnonSwitch>((set) => ({
	isAnon: true,
	setIsAnon: (isAnon) => set({ isAnon }),
}));
