import type { ChannelState } from "../useSubscribeToRoomChannel";

const tw = (strings: TemplateStringsArray) => strings;

type map = {
	[K in ChannelState]: {
		pulse: TemplateStringsArray;
		main: TemplateStringsArray;
	};
};
const indicatorColors: map = {
	LIVE: { pulse: tw` bg-green-400`, main: tw`bg-green-500` },
	DISCONNECTED: { pulse: tw` bg-gray-400`, main: tw`bg-gray-500` },
	JOINING: { pulse: tw`  bg-orange-400`, main: tw`bg-orange-500` },
	CONNECTED: { pulse: tw`  bg-orange-400`, main: tw`bg-orange-500` },
	CHANNEL_ERROR: { pulse: tw` bg-red-400`, main: tw` bg-red-500` },
	CLOSED: { pulse: tw` bg-gray-500`, main: tw`bg-gray-700` },
	OFFLINE: { pulse: tw` bg-black`, main: tw`bg-black` },
} as const;

const LiveIndicator = ({ state }: { state: ChannelState }) => {
	return (
		<span className="relative flex h-3 w-3">
			<span
				className={`animate-ping absolute inline-flex h-full w-full rounded-full bg-sky-400 opacity-75 ${indicatorColors[state].pulse}`}
			/>
			<span
				className={`relative inline-flex rounded-full h-3 w-3 ${indicatorColors[state].main}`}
			/>
		</span>
	);
};

export default LiveIndicator;
