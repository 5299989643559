import { i18n } from "@lingui/core";
import "dayjs/locale/es";
import "dayjs/locale/en";
import { useDayjs } from "./utils/customDayjs";
import { detect, fromStorage, fromNavigator } from "@lingui/detect-locale";

type availableLocales = "es" | "en";
const languageStorageKey = "lang";
export function switchLocale(locale: availableLocales) {
	i18n.activate(locale);
	useDayjs.getState().changeLocale(locale);
	globalThis.localStorage.setItem(languageStorageKey, locale);
}

export function determineUserLocale(): availableLocales {
	const locale = detect(fromStorage(languageStorageKey), fromNavigator());
	if (locale?.startsWith("es")) {
		return "es";
	}
	return "en";
}
