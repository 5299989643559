import { create } from "zustand";

type ShowRepliesStore = {
	showReplies: boolean;
	setShowReplies: (showReplies: boolean) => void;
};

export const useShowRepliesStore = create<ShowRepliesStore>((set) => ({
	showReplies: false,
	setShowReplies: (showReplies) => set({ showReplies }),
}));
