import { Button, buttonVariants } from "@/components/ui/button";
import {
	DialogHeader,
	DialogContent,
	DialogTitle,
	DialogDescription,
	DialogFooter,
} from "@/components/ui/dialog";
import {
	Form,
	FormControl,
	FormField,
	FormItem,
	FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { cn } from "@/lib/utils";
import { zodResolver } from "@hookform/resolvers/zod";
import { msg, t, Trans } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { useNavigate } from "@tanstack/react-router";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { roomRoute } from "src/routes/currentRoomRoute";
import { useCreateRoom } from "src/state/react-query/useRoomData";
import { useOpenCreateRoomDialog } from "src/state/zustand/createRoomDialog";
import { twMerge } from "tailwind-merge";
import { z } from "zod";

type CreateRoomDialogProps = {
	userId: string;
};

const minimumRoomNameLength = 3;
const maximumRoomNameLength = 100;

export const CreateRoomDialog = ({
	userId,
}: CreateRoomDialogProps): JSX.Element => {
	const { _ } = useLingui();
	const { setCreateRoomDialogIsOpen } = useOpenCreateRoomDialog();
	const createRoomQuery = useCreateRoom({ userId });

	const formSchema = z.object({
		roomName: z
			.string()
			.min(
				minimumRoomNameLength,
				t`The room name must at least be 3 characters long.`,
			)
			.max(
				maximumRoomNameLength,
				t`The room name can not be longer than 100 characters.`,
			),
	});

	const form = useForm<z.infer<typeof formSchema>>({
		resolver: zodResolver(formSchema),
		defaultValues: {
			roomName: "",
		},
	});
	const isRoomNameLengthError = form.formState.errors.roomName != null;

	const navigate = useNavigate();

	async function createRoom(values: z.infer<typeof formSchema>) {
		const trimmedRoomName = values.roomName.trim();
		try {
			const roomNumber = await createRoomQuery.mutateAsync({
				roomName: trimmedRoomName,
			});
			navigate({ to: roomRoute.to, params: { roomNumber } });
			setCreateRoomDialogIsOpen(false);
		} catch (e) {
			console.error(e);
			return form.setError("roomName", {
				message: t`Could not create a room.`,
			});
		}
	}

	useEffect(() => {
		if (form.formState.isSubmitSuccessful) {
			form.reset();
		}
	}, [form.formState, form.reset]);

	return (
		<div className="w-full flex flex-col">
			<DialogContent>
				<DialogHeader>
					<DialogTitle className="mb-2">
						<Trans>Add a new room!</Trans>
					</DialogTitle>
				</DialogHeader>
				<Form {...form}>
					<form onSubmit={form.handleSubmit(createRoom)}>
						<DialogDescription>
							<div className="flex flex-col w-full">
								<FormField
									control={form.control}
									name="roomName"
									render={({ field }) => (
										<FormItem className="flex flex-col w-full">
											<FormControl>
												<Input
													placeholder={_(msg`Enter the room name...`)}
													{...field}
													className={`${
														isRoomNameLengthError
															? "ring-red-600 ring"
															: "border-primary"
													} justify-center mx-auto`}
													onChange={field.onChange}
												/>
											</FormControl>
											<p
												className={twMerge(
													"text-xs mt-1 w-fit ml-auto",
													isRoomNameLengthError
														? "  text-red-500"
														: "text-gray-300",
												)}
											>
												{field.value.length}/{maximumRoomNameLength}
											</p>
											<FormMessage className="text-center" />
										</FormItem>
									)}
								/>
							</div>
						</DialogDescription>
						<DialogFooter>
							<Button
								className={`${cn(buttonVariants())} mx-auto mt-3`}
								type="submit"
							>
								<Trans>Create room</Trans>
							</Button>
						</DialogFooter>
					</form>
				</Form>
			</DialogContent>
		</div>
	);
};
