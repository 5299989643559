import { createRootRoute, createRoute } from "@tanstack/react-router";
import { Index } from "src/pages/homePage";
import { routes } from "./routes";
import { RootComponent } from "src/components/RootComponent";

export const rootRoute = createRootRoute({
	component: RootComponent,
	pendingComponent: () => <div>Loading...</div>,
});

// Create an index route
export const indexRoute = createRoute({
	getParentRoute: () => rootRoute,
	path: routes.rootRoute,
	component: Index,
});
