import {
	useFullNameParticipant,
	useIsLoggedIn,
	useLogout,
} from "../../state/react-query/useUserInfoQuery";
import { Avatar, AvatarFallback } from "../../@/components/ui/avatar";
import { Button } from "../../@/components/ui/button";
import {
	DropdownMenu,
	DropdownMenuTrigger,
	DropdownMenuContent,
	DropdownMenuLabel,
	DropdownMenuSeparator,
	DropdownMenuGroup,
	DropdownMenuItem,
} from "../../@/components/ui/dropdown-menu";
import type { User } from "@supabase/supabase-js";
import { UserSettings } from "./UserSettings";
import { Dialog, DialogTrigger } from "@/components/ui/dialog";
import { useNavigate } from "@tanstack/react-router";
import { useEffect, useState } from "react";
import { indexRoute, rootRoute } from "src/routes/rootRoute";
import { shouldShowDebugInformation } from "src/settings";
import { CiCircleList, CiLogout, CiSettings } from "react-icons/ci";
import { Trans } from "@lingui/macro";

export function UserNav({ userData }: { userData: User }): JSX.Element | null {
	const [dialogIsOpen, setDialogIsOpen] = useState(false);
	const [menuIsOpen, setMenuIsOpen] = useState(false);
	const { data: isLoggedIn } = useIsLoggedIn();
	const logout = useLogout();
	const { data: name } = useFullNameParticipant({
		userId: userData.id,
	});
	const navigate = useNavigate();
	const fullName = name?.fullName;
	const initialValueAvatarLetter =
		!isLoggedIn && fullName ? fullName.charAt(0).toUpperCase() : "A";
	const [avatarLetter, setAvatarLetter] = useState(initialValueAvatarLetter);

	useEffect(() => {
		const newAvatarLetter = fullName ? fullName.charAt(0).toUpperCase() : "A";
		setAvatarLetter(newAvatarLetter);
	}, [fullName]);

	const email = userData.email;
	return (
		<Dialog open={dialogIsOpen} onOpenChange={setDialogIsOpen}>
			<DropdownMenu open={menuIsOpen} onOpenChange={setMenuIsOpen}>
				<DropdownMenuTrigger asChild>
					<Button
						className="relative rounded-full "
						size="icon"
						data-testid="profile-button"
					>
						<Avatar className="h-10 w-10">
							<AvatarFallback
								className={`font-semibold text-xl ${menuIsOpen ? "bg-secondary-dark" : "bg-secondary"} drop-shadow-md hover:bg-secondary-dark`}
							>
								{avatarLetter}
							</AvatarFallback>
						</Avatar>
					</Button>
				</DropdownMenuTrigger>
				<DropdownMenuContent className="w-56" align="end" forceMount>
					{shouldShowDebugInformation ? (
						<>
							<div className=" text-xs">{userData.id}</div>
							<div className="text-xs">
								{" "}
								isAnon {!isLoggedIn ? "true" : "false"}
							</div>
						</>
					) : null}
					<DropdownMenuLabel className="font-normal">
						<div className="flex flex-col space-y-1">
							<p className="text-sm font-medium leading-none">
								{fullName || <Trans>Anonymous</Trans>}
							</p>
							{email ? (
								<p
									className="text-xs leading-none text-muted-foreground"
									data-testid="profile-email"
								>
									{email}
								</p>
							) : null}
						</div>
					</DropdownMenuLabel>
					<DropdownMenuSeparator />
					<DropdownMenuGroup>
						<DialogTrigger>
							<DropdownMenuItem className="cursor-pointer">
								<CiSettings className="mr-1" /> <Trans>User settings</Trans>
							</DropdownMenuItem>
						</DialogTrigger>
						<DropdownMenuItem
							className="cursor-pointer"
							onClick={() => navigate({ to: indexRoute.to })}
						>
							<CiCircleList className="mr-1" />
							<Trans>Rooms</Trans>
						</DropdownMenuItem>
					</DropdownMenuGroup>
					<DropdownMenuSeparator />
					<DropdownMenuItem
						className="cursor-pointer"
						onClick={() => {
							logout.mutate();
							navigate({ to: rootRoute.to });
						}}
					>
						<CiLogout className="mr-1" /> <Trans>Log out</Trans>
					</DropdownMenuItem>
				</DropdownMenuContent>
			</DropdownMenu>
			<UserSettings
				userId={userData.id}
				currentEmailUser={email}
				currentFullName={name?.fullName ?? ""}
			/>
		</Dialog>
	);
}
