import { Button } from "@/components/ui/button";
import { Trans } from "@lingui/macro";
import { useNavigate } from "@tanstack/react-router";
import { CiLogin } from "react-icons/ci";
import { signInRoute } from "src/routes/signInRoute";

export const SignInButton = ({
	className,
	isIcon = false,
}: { className?: string; isIcon?: boolean }): JSX.Element => {
	const navigate = useNavigate();

	return (
		<Button
			className={className || "rounded-full"}
			onClick={() => {
				navigate({ to: signInRoute.to });
			}}
			size={isIcon ? "icon" : "default"}
		>
			{isIcon ? <CiLogin className="text-xl" /> : <Trans>Sign in!</Trans>}
		</Button>
	);
};
