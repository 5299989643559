import {
	Table,
	TableBody,
	TableHead,
	TableHeader,
	TableRow,
} from "@/components/ui/table";
import { useRoomsOfUser } from "src/state/react-query/useRoomsOfUser";
import { RoomsTableRow } from "./RoomsTableRow";
import { Trans } from "@lingui/macro";

export const RoomsTable = ({ userId }: { userId: string }): JSX.Element => {
	const { data, isError, isLoading } = useRoomsOfUser({ userId });
	if (isError)
		return (
			<p>
				<Trans>Error!</Trans>
			</p>
		);
	if (isLoading)
		return (
			<p>
				<Trans>Loading...</Trans>
			</p>
		);

	return (
		<Table>
			<TableHeader>
				<TableRow>
					<TableHead>
						<Trans>Name</Trans>
					</TableHead>
					<TableHead>
						<Trans>Created</Trans>
					</TableHead>
					<TableHead />
					<TableHead />
				</TableRow>
			</TableHeader>
			<TableBody>
				{data
					?.filter((r) => r.archivedAt === null)
					.map((room) => (
						<RoomsTableRow key={room.id} userId={userId} {...room} />
					))}
			</TableBody>
		</Table>
	);
};
