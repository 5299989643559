import { Dialog, DialogTrigger } from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { TableCell, TableRow } from "@/components/ui/table";
import { useNavigate } from "@tanstack/react-router";
import { useState } from "react";
import { AiFillDelete, AiFillEdit } from "react-icons/ai";
import { roomRoute } from "src/routes/currentRoomRoute";
import { useChangeRoomName } from "src/state/react-query/useRoomsOfUser";
import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import { FaCircleCheck } from "react-icons/fa6";
import { MdCancel } from "react-icons/md";
import { useDayjs } from "src/utils/customDayjs";
import { DeletePromptRoom } from "./DeletePromptRoom";

type RoomsTableRowProps = {
	roomNumber: string;
	name: string;
	createdAt: string;
	userId: string;
	usersRooms: {
		id: number;
		role: "participant" | "creator";
		userId: string;
	}[];
};

export const RoomsTableRow = ({
	roomNumber,
	name,
	createdAt,
	userId,
	usersRooms,
}: RoomsTableRowProps): JSX.Element => {
	const [showInput, setShowInput] = useState(false);
	const [newRoomName, setNewRoomName] = useState<string | undefined>(undefined);
	const changeRoomName = useChangeRoomName({ roomNumber, userId });
	const navigate = useNavigate();
	const isCreator = usersRooms[0].role === "creator";
	const useDateFromNow = useDayjs((state) => state.getDateFromNow(createdAt));

	const handleEnter: React.KeyboardEventHandler<HTMLInputElement> = (e) => {
		if (e.key === "Enter") {
			handleChangeRoomName();
		}
	};

	const handleChangeRoomName = () => {
		if (newRoomName) {
			changeRoomName.mutate({ newRoomName });
		}
		setShowInput(false);
	};

	return (
		<Dialog>
			<TableRow className="cursor-pointer">
				{showInput ? (
					<TableCell className="flex">
						<Input
							className="text-black mr-2"
							onChange={(e) => setNewRoomName(e.target.value)}
							defaultValue={name}
							onKeyDown={handleEnter}
						/>
						<Button
							variant="ghost"
							className="rounded-full"
							size="icon"
							onClick={handleChangeRoomName}
						>
							<FaCircleCheck
								size="20px"
								className="text-secondary hover:text-primary"
							/>
						</Button>
					</TableCell>
				) : (
					<TableCell
						className="font-medium"
						onClick={() =>
							!showInput &&
							navigate({ to: roomRoute.to, params: { roomNumber } })
						}
					>
						<Badge variant="secondary" className="text-md">
							{name}
						</Badge>
					</TableCell>
				)}
				<TableCell
					onClick={() =>
						!showInput && navigate({ to: roomRoute.to, params: { roomNumber } })
					}
				>
					{useDateFromNow}
				</TableCell>
				<TableCell>
					{isCreator &&
						(!showInput ? (
							<AiFillEdit
								className="text-gray-500 hover:text-gray-800 text-xl"
								onClick={() => setShowInput(true)}
							/>
						) : (
							<MdCancel
								className="text-red-500 hover:text-red-800 text-xl"
								onClick={() => setShowInput(false)}
							/>
						))}
				</TableCell>
				<TableCell>
					{isCreator && (
						<>
							<DialogTrigger>
								<AiFillDelete className="text-gray-500 hover:text-red-800 float-right text-xl" />
							</DialogTrigger>
							<DeletePromptRoom
								roomNumber={roomNumber}
								userId={userId}
								roomName={name}
							/>
						</>
					)}
				</TableCell>
			</TableRow>
		</Dialog>
	);
};
