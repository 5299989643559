import { useMutation, useQuery } from "@tanstack/react-query";
import * as Supabase from "src/supabaseClient";
import { roomInfoKey, roomsOfUser } from "./react-query-keys";
import type { UseRoomsOfUsersProps } from "./useRoomsOfUser";
import { queryClient } from "./client";

export const useRoomData = (roomNumber: string) =>
	useQuery({
		queryKey: [roomInfoKey, roomNumber],
		queryFn: async () =>
			(
				await Supabase.client
					.from("room")
					.select()
					.eq("room_number", roomNumber)
					.single()
					.throwOnError()
			).data,
		staleTime: 10_000,
	});

export const useCreateRoom = ({ userId }: UseRoomsOfUsersProps) => {
	return useMutation({
		mutationFn: ({ roomName }: { roomName: string }) =>
			Supabase.createRoom({ roomName }),
		onSettled: () =>
			queryClient.invalidateQueries({
				queryKey: [roomsOfUser, userId],
			}),
	});
};
