import { Trans } from "@lingui/macro";
import type { ChannelSubscriptionState } from "./useSubscribeToRoomChannel";
import { Spinner } from "@/components/ui/spinner";
type RoomStateHeaderProps = {
	channelState: ChannelSubscriptionState;
	roomNumber: string;
};
export function RoomStateHeader({
	channelState,
	roomNumber,
}: RoomStateHeaderProps): JSX.Element {
	if (channelState.state === "JOINING") {
		return (
			<div>
				<Trans>Joining room...</Trans> {roomNumber} <Spinner />
			</div>
		);
	}
	if (channelState.state === "DISCONNECTED") {
		return (
			<div>
				<Trans>The server has disconnected room...</Trans> {roomNumber}{" "}
				<Spinner />
				<div>{channelState.reason}</div>
			</div>
		);
	}
	if (channelState.state === "CHANNEL_ERROR") {
		return (
			<div>
				<Trans>There was an error while joining room </Trans> {roomNumber}
				<div>{`${channelState.error}`}</div>
			</div>
		);
	}
	return <></>;
}
