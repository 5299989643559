import { useMutation, useQuery } from "@tanstack/react-query";
import camelcaseKeys from "camelcase-keys";
import * as Supabase from "src/supabaseClient";
import { userUpvoteQuestionKey } from "./react-query-keys";
import { queryClient } from "./client";

//TODO: BENT this could result in an additional useless cache. plz fix
export function useUserVoteQuestions({
	questionIds,
	userId,
}: {
	questionIds: string[];
	userId: string | undefined;
}) {
	return useQuery({
		queryKey: [userUpvoteQuestionKey, userId, questionIds],
		queryFn: async () => {
			if (!userId || !questionIds) {
				throw Error(
					`userId (${userId}) or questionIds (${questionIds}) is not defined. This should never happen because of the enabled flag.`,
				);
			}
			const userVotes = (
				await Supabase.client
					.from("question_vote")
					.select()
					.eq("user_id", userId)
					.in("question_id", questionIds)
					.throwOnError()
			).data;
			const correctCamelCase =
				userVotes !== null ? camelcaseKeys(userVotes, { deep: true }) : [];
			//Update all the individual caches.
			for (const questionId in questionIds) {
				const userVote =
					correctCamelCase.find(
						(userVote) => userVote.questionId === questionId,
					) ?? null;
				queryClient.setQueryData(
					[userUpvoteQuestionKey, userId, questionId],
					() => userVote,
				);
			}
			return correctCamelCase;
		},
		enabled: !!userId || !!questionIds,

		staleTime: 10000,
	});
}

export function useUserVoteQuestion({
	questionId,
	userId,
}: { questionId: string | undefined; userId: string | undefined }) {
	return useQuery({
		queryKey: [userUpvoteQuestionKey, userId, questionId],
		queryFn: async () => {
			if (!userId || !questionId) {
				throw Error(
					`userId (${userId}) or questionIds (${questionId}) is not defined. This should never happen because of the enabled flag.`,
				);
			}
			const userVoteQuestion = (
				await Supabase.client
					.from("question_vote")
					.select()
					.eq("user_id", userId)
					.eq("question_id", questionId)
					.limit(1)
					.maybeSingle()
					.throwOnError()
			).data;
			const correctCamelCase =
				userVoteQuestion !== null
					? camelcaseKeys(userVoteQuestion, { deep: true })
					: null;

			return correctCamelCase;
		},
		select: (data) => data?.voteKind,
		enabled: !!userId || !!questionId,
		//TODO: BENT Check if this is correct
		refetchOnMount: false,
		staleTime: 10000,
	});
}

export const useChangeVote = ({
	userId,
	messageId: questionId,
}: { messageId: string; userId: string | undefined }) => {
	return useMutation({
		mutationFn: ({ vote_kind }: { vote_kind: Supabase.voteType }) => {
			if (!userId) {
				throw Error("Missing userId");
			}
			if (
				!queryClient.getQueryData([userUpvoteQuestionKey, userId, questionId])
			) {
				return Supabase.InsertVoteOnQuestion({
					userId,
					questionId,
					vote_kind,
				});
			}
			return Supabase.UpdateVoteOnQuestion({
				userId,
				questionId,
				vote_kind,
			});
		},
		onSettled: () =>
			queryClient.invalidateQueries({
				queryKey: [userUpvoteQuestionKey, userId, questionId],
			}),
	});
};

export const useDeleteVote = ({
	userId,
	messageId: questionId,
}: { messageId: string; userId: string | undefined }) => {
	return useMutation({
		mutationFn: async () => {
			if (!userId) {
				throw Error("Missing userId");
			}
			await Supabase.deleteVoteFromQuestion({ userId, questionId });
		},
		onSettled: async () =>
			await queryClient.invalidateQueries({
				queryKey: [userUpvoteQuestionKey, userId, questionId],
			}),
	});
};
