import { Separator } from "../../@/components/ui/Separator";
import { GoToRoomCard } from "./components/GoToRoomCard";
import { RoomsList } from "./components/RoomsList";

export function Index() {
	return (
		<div className="justify-center">
			<GoToRoomCard />
			<div className="relative flex py-5 items-center max-w-xl w-10/12 m-auto mt-8">
				<Separator content="Or" />
			</div>
			<RoomsList />
		</div>
	);
}
