import { SignInPage } from "src/pages/signInPage/signInPage";
import { rootRoute } from "./rootRoute";
import { createRoute } from "@tanstack/react-router";
import { routes } from "./routes";

export const signInRoute = createRoute({
	getParentRoute: () => rootRoute,
	path: routes.signInRoute,
	component: SignInPage,
});
