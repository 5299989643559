import { Button } from "@/components/ui/button";
import {
	Dialog,
	DialogContent,
	DialogHeader,
	DialogTitle,
} from "@/components/ui/dialog";
import { msg, t, Trans } from "@lingui/macro";
import { useAddFullName } from "src/state/react-query/useUserInfoQuery";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";
import {
	Form,
	FormControl,
	FormField,
	FormItem,
	FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { useLingui } from "@lingui/react";
import {
	useAnonSwitch,
	useOpenFullNameDialog,
} from "src/state/zustand/userNameDialog";
import { useEffect } from "react";

export const AddNameDialog = ({ userId }: { userId: string }) => {
	const { _ } = useLingui();
	const { fullNameDialogIsOpen, setFullNameDialogIsOpen } =
		useOpenFullNameDialog();
	const { setIsAnon } = useAnonSwitch();
	const addFullName = useAddFullName({ userId });

	const formSchema = z.object({
		fullName: z
			.string()
			.min(2, t`Your name must consist of minimal 2 characters.`)
			.max(150, t`Your name can not be longer than 150 characters.`),
	});

	const form = useForm<z.infer<typeof formSchema>>({
		resolver: zodResolver(formSchema),
		defaultValues: {
			fullName: "",
		},
	});

	useEffect(() => {
		if (fullNameDialogIsOpen) {
			form.clearErrors();
		}
	}, [fullNameDialogIsOpen, form.clearErrors]);

	async function onSubmitFullName(values: z.infer<typeof formSchema>) {
		await addFullName.mutateAsync({ fullName: values.fullName, userId });
		setFullNameDialogIsOpen(false);
		setIsAnon(false);
		return;
	}

	return (
		<div className="mx-1">
			<Dialog
				open={fullNameDialogIsOpen}
				onOpenChange={setFullNameDialogIsOpen}
			>
				<DialogContent>
					<DialogHeader>
						<DialogTitle>
							<strong>
								<Trans>Add your name!</Trans>
							</strong>
						</DialogTitle>
					</DialogHeader>

					<Form {...form}>
						<form
							onSubmit={form.handleSubmit(onSubmitFullName)}
							className="space-y-4"
						>
							<FormField
								control={form.control}
								name="fullName"
								render={({ field }) => (
									<FormItem className="flex flex-col justify-start items-center">
										<FormControl>
											<Input
												placeholder={_(msg`Fill in your name`)}
												{...field}
												data-testid="full-name-input-user"
											/>
										</FormControl>
										<FormMessage />
									</FormItem>
								)}
							/>
							<div className="flex justify-center items-center">
								<Button
									className={"mr-2"}
									variant="primary"
									type="submit"
									data-testid="add-full-name-button"
								>
									<Trans>Submit</Trans>
								</Button>
								<Trans>or</Trans>
								<Button
									onClick={() => setFullNameDialogIsOpen(false)}
									className="ml-2"
									variant="outline"
									data-testid="stay-anonymous-button"
								>
									<Trans>Stay anonymous</Trans>
								</Button>
							</div>
						</form>
					</Form>
				</DialogContent>
			</Dialog>
		</div>
	);
};
