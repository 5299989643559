type SeparatorProps = {
	content: string;
};

export const Separator = ({ content }: SeparatorProps) => {
	return (
		<>
			<div className="flex-grow border-t border-gray-400" />
			<span className="flex-shrink mx-4 text-gray-400">{content}</span>
			<div className="flex-grow border-t border-gray-400" />
		</>
	);
};
