import { Input } from "@/components/ui/input";
import { msg } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { type Dispatch, useEffect } from "react";
import { useFullNameParticipant } from "src/state/react-query/useUserInfoQuery";

type NameInputFieldProps = {
	setFullName: Dispatch<React.SetStateAction<string>>;
	userId: string;
	handleKeyDown: (event: React.KeyboardEvent<HTMLInputElement>) => void;
	className?: string;
	fullName: string;
};

export const NameInputField = ({
	userId,
	handleKeyDown,
	className,
	setFullName,
	fullName,
}: NameInputFieldProps): JSX.Element => {
	const { _ } = useLingui();
	const nameParticipant = useFullNameParticipant({ userId }).data?.fullName;
	useEffect(() => {
		if (nameParticipant) {
			setFullName(nameParticipant);
		}
	}, [nameParticipant, setFullName]);

	return (
		<Input
			className={className || ""}
			placeholder={_(msg`Enter your name`)}
			onChange={(e) => setFullName(e.target.value)}
			onKeyDown={(e) => handleKeyDown(e)}
			value={fullName}
		/>
	);
};
