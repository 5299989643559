import { Button } from "@/components/ui/button";
import {
	ColorFilledCard,
	CardContent,
	CardFooter,
	CardHeader,
	CardTitle,
} from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { msg, Trans } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { useNavigate } from "@tanstack/react-router";
import { useState } from "react";
import { ErrorMessage } from "src/components/ErrorMessage";
import { roomRoute } from "src/routes/currentRoomRoute";
import { useJoinRoomLoggedInOrAnonymously } from "src/state/react-query/useUserInfoQuery";
import { twMerge } from "tailwind-merge";

const maximumRoomNumberLength = 8;

// eslint-disable-next-line react-refresh/only-export-components
export const addSpacingToRoomNumber = (roomNumber: string) =>
	roomNumber.length > 4
		? `${roomNumber.slice(0, 4)} ${roomNumber.slice(4)}`
		: roomNumber;

export const GoToRoomCard = (): JSX.Element => {
	const [roomNumber, setRoomNumber] = useState("");
	const [isRoomNumberLengthError, setIsRoomNumberLengthError] = useState(false);
	const mutation = useJoinRoomLoggedInOrAnonymously();
	const { _ } = useLingui();

	const navigate = useNavigate();

	async function goToRoom() {
		if (roomNumber.length !== maximumRoomNumberLength) {
			return setIsRoomNumberLengthError(true);
		}
		setIsRoomNumberLengthError(false);
		await mutation.mutateAsync({ roomNumber });
		navigate({ to: roomRoute.to, params: { roomNumber } });
	}

	const handleEnter: React.KeyboardEventHandler<HTMLInputElement> = (e) => {
		if (e.key === "Enter") {
			goToRoom();
		}
	};

	return (
		<>
			{isRoomNumberLengthError && (
				<ErrorMessage errorMessage="Please fill in 8 numbers." />
			)}
			<ColorFilledCard className="mt-10">
				<CardHeader className="p-6">
					<CardTitle>
						<Trans>Join a room</Trans>
					</CardTitle>
				</CardHeader>
				<CardContent className="p-6 pt-0">
					<div className="flex flex-col w-full">
						<Input
							value={addSpacingToRoomNumber(roomNumber)}
							maxLength={maximumRoomNumberLength + 1}
							className={`max-w-xl ${
								isRoomNumberLengthError ? "ring-red-600 ring" : undefined
							}`}
							onChange={(e) => {
								const newValue = e.target.value.replace(/\D/g, "");
								if (newValue.length <= maximumRoomNumberLength) {
									setIsRoomNumberLengthError(false);
									return setRoomNumber(newValue);
								}
							}}
							onKeyDown={handleEnter}
							placeholder={_(msg`Enter the room number...`)}
							data-testid="room-number-input"
						/>
						<p
							className={twMerge(
								"text-xs mt-1 w-fit ml-auto",
								isRoomNumberLengthError ? "  text-red-500" : "text-gray-200",
							)}
						>
							{roomNumber.length}/{maximumRoomNumberLength}
						</p>
					</div>
				</CardContent>
				<CardFooter className="p-6 pt-0">
					<Button
						className="mx-auto"
						onClick={goToRoom}
						data-testid="join-room-button"
					>
						<Trans>Join</Trans>
					</Button>
				</CardFooter>
			</ColorFilledCard>
		</>
	);
};
