import { useMutation } from "@tanstack/react-query";
import * as Supabase from "src/supabaseClient";
import { queryClient } from "./client";
import { questionsKey } from "./react-query-keys";

export const useUpdateAnswered = ({
	questionId,
	roomId,
}: { roomId: string; questionId: string }) => {
	return useMutation({
		mutationFn: ({ answered_at }: { answered_at: string | null }) =>
			Supabase.updateAnswered({ answered_at, questionId }),
		onSettled: () =>
			queryClient.invalidateQueries({
				queryKey: [questionsKey, roomId],
			}),
	});
};
