import { buttonVariants } from "@/components/ui/button";
import {
	DialogClose,
	DialogContent,
	DialogDescription,
	DialogHeader,
	DialogTitle,
} from "@/components/ui/dialog";
import { cn } from "@/lib/utils";
import { Trans } from "@lingui/macro";
import { useDeleteRooms } from "src/state/react-query/useRoomsOfUser";

type DeletePromptProps = {
	roomNumber: string;
	userId: string;
	roomName: string;
};

export const DeletePromptRoom = ({
	roomNumber,
	userId,
	roomName,
}: DeletePromptProps) => {
	const deleteRoom = useDeleteRooms({ roomNumber, userId });

	const handleDelete = () => {
		deleteRoom.mutate();
	};
	return (
		<DialogContent>
			<DialogHeader>
				<DialogTitle>
					<Trans>Are you sure you want to delete this room?</Trans>
				</DialogTitle>
				<DialogDescription>
					<Trans>
						This action cannot be undone. This will permanently delete room
					</Trans>{" "}
					<strong> {roomName} </strong>
					<Trans> and its data from our server.</Trans>
				</DialogDescription>
			</DialogHeader>
			<div className="flex justify-center items-center">
				<DialogClose
					className={`${cn(buttonVariants({ variant: "destructive" }))} m-2`}
					onClick={handleDelete}
				>
					<Trans>Delete</Trans>
				</DialogClose>
			</div>
		</DialogContent>
	);
};
