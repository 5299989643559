import day from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import utc from "dayjs/plugin/utc";
import { create } from "zustand";

day.extend(relativeTime);
day.extend(utc);

const dayjs = day;

type UseDateFromNowState = {
	dayjs: typeof dayjs;
	getDateFromNow: (createdAt: string) => string;
	changeLocale: (locale: string) => void;
};

export const useDayjs = create<UseDateFromNowState>((set, get) => ({
	dayjs: dayjs,
	getDateFromNow: (createdAt) => get().dayjs(createdAt).fromNow(),
	changeLocale: (locale) => {
		const dayjs = get().dayjs;
		dayjs.locale(locale);
		set({ dayjs });
	},
}));
