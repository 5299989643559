import { Alert } from "@/components/ui/alert";
import { Trans } from "@lingui/macro";

export const ErrorMessage = ({
	errorMessage,
	className,
}: { errorMessage: string; className?: string }): JSX.Element => {
	return (
		<div className={className}>
			<Alert className="my-4 mx-auto" variant="destructive">
				<strong>
					<Trans>Error!</Trans> {errorMessage}
				</strong>
			</Alert>
		</div>
	);
};
