import {
	type UseQueryResult,
	useMutation,
	useQuery,
} from "@tanstack/react-query";
import * as Supabase from "src/supabaseClient";
import { questionsKey } from "./react-query-keys";
import camelcaseKeys from "camelcase-keys";
import type { MessageTypeCamelCase } from "src/utils/messageSchema";
import type { QueryError } from "@supabase/supabase-js";

type RoomId = { roomId: string };

export const useQuestions = ({
	roomId,
}: RoomId): UseQueryResult<MessageTypeCamelCase[], QueryError> =>
	useQuery({
		queryKey: [questionsKey, roomId],
		queryFn: async () => {
			const questions =
				(
					await Supabase.client
						.from("question")
						.select()
						.eq("room_id", roomId)
						.order("upvotes", { ascending: false })
						.order("created_at", { ascending: false })
						.throwOnError()
				).data ?? [];
			const camelCaseQuestions: MessageTypeCamelCase[] = camelcaseKeys(
				questions,
				{ deep: true },
			);
			return camelCaseQuestions;
		},
		enabled: !!roomId,

		staleTime: 10_000,
	});

export const usePostQuestion = () =>
	useMutation({
		mutationFn: Supabase.postQuestion,
	});

export const usePostReply = () =>
	useMutation({
		mutationFn: Supabase.postReply,
	});

export const useDeleteMessage = () =>
	useMutation({
		mutationFn: Supabase.deleteMessage,
	});

export const useEditMessage = () =>
	useMutation({
		mutationFn: Supabase.updateMessage,
	});
