import { useDayjs } from "./customDayjs";

//TODO: remove sortNewestFirst: dead code, could still be useful
export function sortNewestFirst<T>(arr: T[], accessor: (t: T) => string) {
	return arr.sort((a, b) => {
		return useDayjs.getState().dayjs(accessor(a)).isAfter(accessor(b)) ? -1 : 1;
	});
}
export function sortOnVotesAndOnDate<
	T extends { upvotes: number; created_at: string },
>(questions: T[]): T[] {
	return questions.sort((a, b) => {
		if (a.upvotes === b.upvotes) {
			return useDayjs.getState().dayjs(a.created_at).isAfter(b.created_at)
				? -1
				: 1;
		}
		return b.upvotes - a.upvotes;
	});
}
