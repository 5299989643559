import { create } from "zustand";

type UseOpenCreateRoomDialog = {
	createRoomDialogIsOpen: boolean;
	setCreateRoomDialogIsOpen: (a: boolean) => void;
};

export const useOpenCreateRoomDialog = create<UseOpenCreateRoomDialog>(
	(set) => ({
		createRoomDialogIsOpen: false,
		setCreateRoomDialogIsOpen: (createRoomDialogIsOpen) =>
			set({ createRoomDialogIsOpen: createRoomDialogIsOpen }),
	}),
);
